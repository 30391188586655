import React, { useEffect, useState } from 'react';
import { compose } from "redux";
import arrayMutators from 'final-form-arrays';
import { injectIntl } from "react-intl";
import { Form as FinalForm } from "react-final-form";
import { Button, FieldCheckboxGroup, FieldDateInput, FieldLocationAutocompleteInput, Form } from '../../../../../components';
import { MultiSelect } from 'primereact/multiselect';
import css from './SearchFieldForm.module.css';
import '../../../../../assets/theme.css';

const SearchFieldsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    keepDirtyOnReinitialize={true}
    render={fieldRenderProps => {

      const {
        rootClassName,
        className,
        handleSubmit,
        form,
        formId,
        values,
        autoFocus,
        invalid,
        listingFieldsConfig,
      } = fieldRenderProps;

      const [showContent, setShowContent] = useState(false);
      const [selectedServices, setSelectedServices] = useState(null);
      const data = listingFieldsConfig.map(items => { return { categoryName: items.key, subCategories: items.enumOptions } });
      const subCategories = listingFieldsConfig.map(item => item.enumOptions.map(values => { return { key: values.option, label: values.label } })).flat();
      const requiredDataFormat = listingFieldsConfig.map(items => { return { mainCategory: items?.showConfig?.label, items: items?.enumOptions } });

      useEffect(() => {
        form.change("categories", [...data]);
      }, [data.length]);

      const handleSelectService = (e) => {
        setSelectedServices(e.value);
        const myVal = e.value?.map(item => item?.option);
        form.change("task", myVal);
      };

      const groupedItemTemplate = (option) => {
        return (
          <div className="flex align-items-center">
            <div className={css.mainOptionHeading}>{option.mainCategory}</div>
          </div>
        );
      };

      return (
        <Form onSubmit={handleSubmit}>
          <div className={css.searchForm}>
            <div>
              <FieldLocationAutocompleteInput
                className={css.locationInput}
                name={formId ? `${formId}.where` : "where"}
                placeholder={'Where'}
                useDefaultPredictions={true}
                format={(v) => v}
                valueFromForm={values.where}
              />
              <span className={css.neighHood}>Enter Your Neighbourhood...</span>
            </div>
            <FieldDateInput
              className={css.dateInput}
              id={"date"}
              name={"when"}
              useMobileMargins={false}
              label={""}
              placeholderText={"When"}
              format={(v) => v}
            />
            <div className={css.taskWrapper}>
              {/* <MultiSelect
                                value={selectedServices}
                                onChange={(e) => handleSelectService(e)}
                                options={subCategories}
                                optionLabel="label"
                                placeholder="Select Services"
                                maxSelectedLabels={0}
                                className="w-full md:w-20rem"
                            /> */}
              <MultiSelect
                maxSelectedLabels={0}
                value={selectedServices}
                options={requiredDataFormat}
                onChange={(e) => handleSelectService(e)}
                optionLabel="label"
                optionGroupLabel="label"
                optionGroupChildren="items"
                optionGroupTemplate={groupedItemTemplate}
                placeholder="Select Services"
                display="chip"
                className={css.multiSelect}
              />
            </div>
            <div className={css.searchButton}>
              <Button>Search</Button>
            </div>
          </div>
        </Form>
      )
    }}
  />
)

const SearchComponent = compose(injectIntl)(SearchFieldsFormComponent);
SearchComponent.displayName = "Search Component";
export default SearchComponent;